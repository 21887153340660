import React from 'react';
import { Link } from 'react-router-dom';

function CategoryPage() {
  return (
    <div className="category-page">
      <h1>Categories of Coloring Pages</h1>
      <p>
        Find the best coloring pages for kids! Browse by category, download and print free coloring sheets, 
        or color online at <Link to="/" title="Online Coloring Pages for Kids">coloringbook.online</Link>!
      </p>
      <div className="categories-container">
        <Link to="/category/animals" className="category-card">Animals</Link>
        <Link to="/category/plants" className="category-card">Plants</Link>
        <Link to="/category/cartoon" className="category-card">Cartoon Characters</Link>
        <Link to="/category/vehicles" className="category-card">Vehicles</Link>
      </div>
    </div>
  );
}

export default CategoryPage; 