import React from 'react';
import { Link } from 'react-router-dom';

function GameCard({ id, title, thumbnail, description }) {
  return (
    <div className="game-card">
      <img src={thumbnail} alt={title} className="game-thumbnail" />
      <h3>{title}</h3>
      <p>{description}</p>
      <Link to={`/game/${id}`} className="play-button">
        Start Coloring
      </Link>
    </div>
  );
}

export default GameCard; 