import React from 'react';
import { useParams, Link } from 'react-router-dom';

const CategoryDetailPage = () => {
  const { categoryName } = useParams();

  return (
    <div className="container mx-auto py-8">
      <h1 className="text-3xl font-bold mb-4">
        {categoryName} Coloring Pages
      </h1>
      <p>
        Find the best {categoryName} coloring pages for kids! Download and
        print free coloring sheets, or color online at{' '}
        <Link to="/" title="Online Coloring Pages for Kids">
          coloringbook.online
        </Link>!
      </p>
      <div className="games-container">
        <p>Loading {categoryName} coloring pages...</p>
      </div>
    </div>
  );
};

export default CategoryDetailPage; 