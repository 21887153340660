import React, { useState, useEffect } from 'react';
import GameCard from '../components/GameCard';

function HomePage() {
  const [games, setGames] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Fetch game data with cache-busting query parameter
    const timestamp = new Date().getTime(); // Add timestamp to prevent caching
    fetch(`/games.json?t=${timestamp}`)
      .then(response => response.json())
      .then(data => {
        setGames(data);
        setLoading(false);
      })
      .catch(error => {
        console.error('Failed to load games:', error);
        setLoading(false);
      });
  }, []);

  // 添加刷新按钮的功能
  const refreshGames = () => {
    setLoading(true);
    const timestamp = new Date().getTime();
    fetch(`/games.json?t=${timestamp}`)
      .then(response => response.json())
      .then(data => {
        setGames(data);
        setLoading(false);
      })
      .catch(error => {
        console.error('Failed to load games:', error);
        setLoading(false);
      });
  };

  return (
    <div className="home-page">
      <h1>Coloringbook.online</h1>
      <p>Fun and free coloring pages for kids of all ages!</p>
      
      <div className="section-header">
        <h2 className="section-title">MOST PLAYED GAMES</h2>
        <button 
          onClick={refreshGames} 
          className="refresh-button"
          title="刷新游戏列表"
        >
          🔄 刷新
        </button>
      </div>
      
      <div className="games-container">
        {loading ? (
          <p>Loading coloring pages...</p>
        ) : games.length > 0 ? (
          games.map(game => (
            <GameCard
              key={game.id}
              id={game.id}
              title={game.title}
              description={game.description}
              thumbnail={game.thumbnail}
            />
          ))
        ) : (
          <p>No coloring pages available</p>
        )}
      </div>
    </div>
  );
}

export default HomePage; 