import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

function GameDetailPage() {
  const { gameId } = useParams();
  const [game, setGame] = useState(null);
  const [loading, setLoading] = useState(true);
  
  useEffect(() => {
    // Fetch game data based on gameId
    fetch('/games.json')
      .then(response => response.json())
      .then(data => {
        const gameData = data.find(g => g.id === parseInt(gameId));
        setGame(gameData);
        setLoading(false);
      })
      .catch(error => {
        console.error('Error loading game:', error);
        setLoading(false);
      });
  }, [gameId]);
  
  return (
    <div className="game-detail-page">
      {loading ? (
        <p>Loading game...</p>
      ) : game ? (
        <>
          <h1>{game.title}</h1>
          <p>{game.description}</p>
          
          <div className="game-canvas-container">
            {game.iframeUrl ? (
              <iframe 
                src={game.iframeUrl} 
                title={game.title}
                width="100%" 
                height="100%" 
                frameBorder="0" 
                allowFullScreen
              ></iframe>
            ) : (
              <div className="placeholder-canvas">
                <p>Game content will appear here</p>
                <p>This is a demo placeholder for the coloring game</p>
              </div>
            )}
          </div>
          
          <div className="color-palette">
            <div className="color" style={{ backgroundColor: 'red' }}></div>
            <div className="color" style={{ backgroundColor: 'blue' }}></div>
            <div className="color" style={{ backgroundColor: 'green' }}></div>
            <div className="color" style={{ backgroundColor: 'yellow' }}></div>
            <div className="color" style={{ backgroundColor: 'purple' }}></div>
            <div className="color" style={{ backgroundColor: 'orange' }}></div>
            <div className="color" style={{ backgroundColor: 'pink' }}></div>
            <div className="color" style={{ backgroundColor: 'brown' }}></div>
          </div>
        </>
      ) : (
        <p>Game not found</p>
      )}
    </div>
  );
}

export default GameDetailPage; 