import React from 'react';
import { Link } from 'react-router-dom';
import SearchBar from './SearchBar';

function Navigation() {
  const handleSearch = (query) => {
    console.log('Search:', query);
  };

  return (
    <nav className="navigation">
      <div className="logo">
        <Link to="/">
          <img src="/logo192.png" alt="Coloringbook.online Logo" height="50" />
          <span>Coloringbook.online</span>
        </Link>
      </div>
      <div className="nav-links">
        <Link to="/">Home</Link>
        <Link to="/categories">Categories</Link>
        <Link to="/popular">Popular</Link>
        <Link to="/new">New</Link>
      </div>
      <SearchBar onSearch={handleSearch} />
    </nav>
  );
}

export default Navigation; 